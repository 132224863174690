<script setup lang="ts">
import { InternalRouteName } from '~/constants/internal-route-name'
import VFullScreenMedia from '~/components/molecules/VFullScreenMedia/VFullScreenMedia.vue'
import { getStructuredDataScript } from '~/utils/structured-data'

interface ReservationLayoutProps {
    withReservationForm?: boolean
    displaySummary?: boolean
}

const { category, departureAgency, returnAgency, departureTime, returnTime, breadcrumbItems, supportAccessories } =
    useReservationParams()
const $style = useCssModule()

withDefaults(defineProps<ReservationLayoutProps>(), {
    withReservationForm: true,
})

const reservationOverlayOpen = ref(false)
const route = useRoute()
const backRoute = computed(() => {
    const currentPageName = route.meta.name
    const { category, depAgency, depTime, retTime, vehicle } = route.params || {}

    if (!category || !depAgency || !depTime || !retTime) return

    if (
        currentPageName === InternalRouteName.RESERVATION_OPTIONS ||
        currentPageName === InternalRouteName.RESERVATION_REQUEST
    ) {
        return {
            name: InternalRouteName.RESERVATION_LISTING,
            params: { category, depAgency, depTime, retTime },
        }
    } else if (currentPageName === InternalRouteName.RESERVATION_ACCESSORIES) {
        return {
            name: InternalRouteName.RESERVATION_OPTIONS,
            params: { category, depAgency, depTime, retTime, vehicle },
        }
    } else if (currentPageName === InternalRouteName.RESERVATION_SUMMARY) {
        return {
            name: supportAccessories.value
                ? InternalRouteName.RESERVATION_ACCESSORIES
                : InternalRouteName.RESERVATION_OPTIONS,
            params: { category, depAgency, depTime, retTime, vehicle },
        }
    }
})

const reservationPageListWithBreadcrumb: string[] = [
    InternalRouteName.RESERVATION_ACCESSORIES,
    InternalRouteName.RESERVATION_OPTIONS,
    InternalRouteName.RESERVATION_LISTING,
    InternalRouteName.RESERVATION_SUMMARY,
    InternalRouteName.RESERVATION_REQUEST,
]

// TODO: update Breadcrumd for request page
const hasRentalBreadcrumb = computed(() => {
    return route.name && reservationPageListWithBreadcrumb.includes(route.name)
})

const router = useRouter()
const { $joinSiteUrl } = useNuxtApp()
const structuredDataScript = getStructuredDataScript({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    numberOfItems: breadcrumbItems.value.length,
    itemListElement: breadcrumbItems.value.map((item, index) => {
        const id = `page-${item?.route?.name?.toString() || item.name}`
        const relativePath = item?.link || (item.route && router.resolve(item.route)?.href)

        return {
            '@type': 'ListItem',
            position: index + 1,
            item: {
                '@id': id,
                name: item?.name || item?.route?.name,
                url: relativePath ? $joinSiteUrl(relativePath) : undefined,
            },
        }
    }),
})
</script>

<template>
    <div class="grid">
        <header class="grid-item-full-screen" :class="$style.header">
            <component :is="structuredDataScript" />

            <VTopBar>
                <template v-if="hasRentalBreadcrumb">
                    <LazyVReservationBreadcrumb :steps="breadcrumbItems" :class="$style.breadcrumb" />
                </template>
            </VTopBar>

            <LazyVReservationBreadcrumb
                v-if="hasRentalBreadcrumb"
                scrollable
                :steps="breadcrumbItems"
                :class="$style['breadcrumb--mobile']"
            />

            <VFullScreenMedia src="/images/car-filigrane.webp" />
        </header>
        <div :class="$style['title-group']" class="grid-item-main">
            <slot name="title" />
            <div :class="$style['title-group__meta']">
                <slot name="meta" />
                <VButton v-if="backRoute" outlined :to="backRoute" :icon-last="false" :label="$t('back_link')">
                    <template #icon>
                        <SvgIcon name="chevron-left" width="24" height="24" viewBox="0 0 24 24" />
                    </template>
                </VButton>
                <slot name="reservation">
                    <VButton
                        v-if="withReservationForm && !reservationOverlayOpen"
                        :class="$style['booking-button']"
                        outlined
                        :label="$t('edit_booking')"
                        @click.prevent="reservationOverlayOpen = true"
                    >
                        <template #icon>
                            <SvgIcon name="edit" view-box="0 0 24 24" width="24" height="24" />
                        </template>
                    </VButton>
                </slot>
            </div>

            <LazyVReservationOverlay
                v-if="withReservationForm"
                :opened="reservationOverlayOpen"
                :category="category"
                :departure-agency="departureAgency"
                :departure-time="departureTime"
                :return-agency="returnAgency"
                :return-time="returnTime"
                @update:opened="(v) => (reservationOverlayOpen = v)"
            />
        </div>
        <main
            :class="[$style.main, displaySummary && $style['main--condensed']]"
            class="grid grid-item-main-mobile-wide"
        >
            <slot />
            <LazyVReservationSummary v-if="displaySummary" :class="$style.aside">
                <slot name="aside-button" />
            </LazyVReservationSummary>
        </main>
        <v-footer class="grid-item-full-screen" />
    </div>
</template>

<style lang="scss" module>
.header {
    @include media('<vl') {
        overflow-x: hidden;
    }
}

.breadcrumb {
    --v-reservation-breadcrumb-display: none;

    @include media('>=vl') {
        --v-reservation-breadcrumb-display: flex;
    }
}

.breadcrumb--mobile {
    --v-reservation-breadcrumb-item-height: #{rem(32)};
    --v-reservation-breadcrumb-display: flex;

    margin-top: rem(50);

    @include media('>=md') {
        margin-block: rem(38) rem(20);
    }

    @include media('>=vl') {
        --v-reservation-breadcrumb-display: none;
    }
}

.media-wrapper {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: rem(500);
    inset: 0;
    pointer-events: none;
}

.header-media {
    grid-column: 1 / -1;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.header-group {
    position: relative;
    padding: var(--spacing-2-xs, 16px) 0;
}

.title-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: rem(16);
    padding-block: var(--spacing-md) rem(24);

    @include media('>=md') {
        justify-content: space-between;
    }

    @include media('>=vl') {
        padding-top: 96px - 12px;
    }
}

.title-group__meta {
    display: flex;
    align-items: center;
    gap: rem(30) rem(12);

    @include media('>=md') {
        gap: rem(30) rem(20);
    }
}

.booking-button {
    @include media('<md') {
        --v-button-label-display: none;
    }
}

.main {
    margin-bottom: rem(48);
    gap: rem(48) var(--gutter);

    > *:first-child {
        width: 100%;
        grid-column: 1 / -1;
    }

    &--condensed {
        align-items: flex-start;

        @include media('>=lg') {
            --grid-columns: 22;

            > *:first-child {
                grid-column: 1 / 14;
            }
        }

        @include media('>=vl') {
            > *:first-child {
                grid-column: 1 / 16;
            }
        }
    }
}

.aside {
    position: sticky;
    top: rem(24);
    padding: var(--spacing-md) var(--spacing-sm);
    border-radius: rem(16);
    background-color: #fff;
    grid-column: 1 / -1;

    @include media('>=lg') {
        padding: var(--spacing-md);
        grid-column: -10 / -1;
    }

    @include media('>=vl') {
        grid-column: -8 / -1;
    }

    > button:last-child {
        display: flex;
        margin-top: rem(22);
        margin-inline: auto;
    }
}
</style>
